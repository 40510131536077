import React from 'react'
import Sticky from 'react-sticky-el'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import SideBarMenu from './Sidebar'
import './header.css'
import logoMain from '../assets/images/logo.png'
import BBBlogo from '../assets/images/bbb.svg'

import Menu from './Menu'

const Header = () => (
  <header>
    <Sticky
      className="imSticky"
      stickyClassName="is-sticky"
      stickyStyle={{ transform: 'unset', zIndex: '2' }}
    >
      <div className="menu-container">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-8 col-md-8 col-lg-2 text-left pr-0">
              <a href="/" className="">
                <img
                  src={logoMain}
                  alt="Perfectly Matched"
                  className="m-0"
                  width="100%"
                />
              </a>
              <div className="d-none d-lg-block mt-2">
                <a
                  class="btn-global btn-block text-center nav-cta py-2"
                  href="tel:+17192601000"
                >
                  (719) 260-1000
                </a>
              </div>
            </div>
            <div className="col-md-7 text-center d-none d-lg-inline-block">
              <div className="navigation-head">
                <div className="navigation-list">
                  <div className="navigation p-0">
                    <LazyLoadComponent>
                      <Menu />
                    </LazyLoadComponent>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 text-right d-none d-lg-inline-block">
              <a
                href="https://www.bbb.org/us/co/colorado-springs/profile/dating-services/perfectly-matched-0785-2601000"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={BBBlogo}
                  alt="BBB Accredited Business"
                  width="125px"
                  className="m-0"
                />
              </a>
              <a
                className="social-btn"
                href="https://www.facebook.com/perfectlymatched"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook" />
              </a>
            </div>
            {/* burger - shows on mobile only */}
            <div className="col-3 text-right d-lg-none d-inline-block">
              <LazyLoadComponent>
                <SideBarMenu />
              </LazyLoadComponent>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
