import React from 'react'
import { Link } from 'gatsby'
import { Dropdown } from 'react-bootstrap'
import BBBlogo from '../assets/images/bbb.svg'

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      windowWidth: undefined,
      dropdown: false,
      slice: 8,
      menu: [
        {
          name: 'Home',
          href: '/',
        },
        {
          name: 'About Donna',
          href: '/about',
        },
        {
          name: 'Membership',
          href: '/membership',
        },
        {
          name: 'Testimonials',
          href: '/testimonials',
        },
        {
          name: 'Profile Test',
          href: '/profile-test',
        },
        {
          name: 'Dear Donna',
          href: '/blog',
        },
        {
          name: 'Contact',
          href: '/contact',
        },
      ],
    }
    this.onToggle = this.onToggle.bind(this)
    this.headerNav = React.createRef()
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  onToggle() {
    this.setState({
      dropdown: !this.state.dropdown,
    })
  }
  handleResize = () =>
    //how many menu items to show for responsiveness
    this.setState({
      windowWidth: window.innerWidth,
      slice:
        window.innerWidth > 2084
          ? // ? 7
            // : window.innerWidth > 1366
            7
          : window.innerWidth > 1024
          ? 7
          : window.innerWidth > 768
          ? 3
          : 1,
    })

  render() {
    console.log(this.state.slice)
    console.log(this.state.windowWidth)

    const menu = this.state.menu
      ? this.state.menu.slice(0, this.state.slice)
      : this.state.menu

    const dropdownMenu = this.state.menu
      ? this.state.menu.slice(this.state.slice)
      : null

    return (
      <div>
        {/***** to display mobile menu *****/}
        <div className="d-block d-lg-none px-3 py-5">
          <ul className="text-center m-0">
            {menu.map(menuItem => (
              <li>
                <Link to={menuItem.href} className="nav-item">
                  {menuItem.name}
                </Link>
              </li>
            ))}
            {dropdownMenu &&
              dropdownMenu.map(menuList => (
                <li>
                  <Link to={menuList.href} className="nav-item">
                    {menuList.name}
                  </Link>
                </li>
              ))}{' '}
          </ul>
          <div className="text-center">
            <a
              href="https://www.bbb.org/us/co/colorado-springs/profile/dating-services/perfectly-matched-0785-2601000/complaints"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={BBBlogo}
                alt="BBB Accredited Business"
                width="125px"
                className="m-0"
              />
            </a>
            <a
              className="social-btn"
              href="https://www.facebook.com/perfectlymatched"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook" />
            </a>
            <a class="btn-global d-block mt-4" href="tel:+17192601000">
              (719) 260-1000
            </a>
          </div>
        </div>

        {/***** to display desktop menu *****/}
        <div className="d-none d-lg-block">
          <ul ref={this.headerNav} className="headerNav">
            {menu.map(menuItem => (
              <li>
                <Link to={menuItem.href} className="nav-item">
                  {menuItem.name}
                </Link>
              </li>
            ))}
            <li>
              {this.state.slice < 7 ? (
                <Dropdown isOpen={this.state.dropdown} toggle={this.onToggle}>
                  <Dropdown.Toggle caret>More</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul className="dropdown-nav">
                      {dropdownMenu &&
                        dropdownMenu.map(menuList => (
                          <li>
                            <Link to={menuList.href} className="nav-item">
                              {menuList.name}
                            </Link>
                          </li>
                        ))}{' '}
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Menu
