import React from 'react'
import { Link } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.css'
import Header from './Header'
import '../components/style.css'
import logo from '../assets/images/logo-white.png'
import Form from '../components/Form'
import 'font-awesome/css/font-awesome.css'

class Layout extends React.Component {
  render() {
    // const { location, title, children } = this.props
    const { children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`
    // let header

    // if (location.pathname === rootPath) {
    //   header = (
    //     <h1
    //       style={{
    //         ...scale(1.5),
    //         marginBottom: rhythm(1.5),
    //         marginTop: 0,
    //       }}
    //     >
    //       <Link
    //         style={{
    //           boxShadow: `none`,
    //           textDecoration: `none`,
    //           color: `inherit`,
    //         }}
    //         to={`/`}
    //       >
    //         {title}
    //       </Link>
    //     </h1>
    //   )
    // } else {
    //   header = (
    //     <h3
    //       style={{
    //         fontFamily: `Montserrat, sans-serif`,
    //         marginTop: 0,
    //       }}
    //     >
    //       <Link
    //         style={{
    //           boxShadow: `none`,
    //           textDecoration: `none`,
    //           color: `inherit`,
    //         }}
    //         to={`/`}
    //       >
    //         {title}
    //       </Link>
    //     </h3>
    //   )
    // }
    return (
      <div>
        <Header />
        <div>
          {children}
          <div className="sub-footer">
            <div className="container">
              <div className="row">
                <div className="col-md-6 text-white mb-5 mb-md-0">
                  <img
                    className="img-fluid footer-logo"
                    src={logo}
                    alt="Perfectly Matched Logo"
                  />
                  <p className="mt-3 text-left mb-0">
                    If you're ready to find your perfect match, contact me today
                    by phone or take ten minutes to fill out the Compatibility
                    Test, and let's get started finding the one who is out there
                    searching for you!
                    <br />
                    <br />
                  </p>

                  <h3 className="mt-2">Benchmarks</h3>
                  <ul className="styled-list">
                    <li>
                      30 years of Colorado Springs matchmaker experience.
                      <br />
                    </li>
                    <li>
                      Responsible for 700 married men and women.
                      <br />
                    </li>
                    <li>
                      Proven Compatibility/Personality Profile Testing.
                      <br />
                    </li>
                    <li>
                      Optional background checks.
                      <br />
                    </li>
                    <li>
                      Age, divorce verification.
                      <br />
                    </li>
                  </ul>
                  <div className="mt-5">
                    <a className="btn-global" href="tel:+17192601000">
                      Call me: (719) 260-1000
                    </a>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-container">
                    <div className="intro">
                      <h4 className="mt-0 mb-0">
                        FILL IN THE FORM BELOW TO SEND ME AN EMAIL.
                      </h4>
                      <p>
                        If you want to hear from me about relationship advice,
                        tips on dating and special offers.
                      </p>
                    </div>
                    <Form
                      form_name="Contact Form"
                      form_id="3faa6b3a-7d9d-4200-84af-c5c65632a660"
                      form_classname="contact-form"
                      scriptSrc="https://staging-forms.webriq.com/js/initReactForms"
                    >
                      <div className="form-row">
                        <div className="col-12 mb-3">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="email"
                              name="Email Address"
                              id="email_address"
                              required
                            />
                            <label htmlFor="email_address">
                              Enter Your Email Address
                            </label>
                          </div>
                          <small className="text-dark form-text mt-0">
                            We will never share your email with anyone else.
                          </small>
                        </div>
                        <div className="col-12 mb-3">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="Name"
                              id="full_name"
                              required
                            />
                            <label htmlFor="full_name">Enter Your Name</label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group m-0 contact-dropdown">
                            <label
                              htmlFor="program"
                              className="position-relative p-0"
                            >
                              How did you hear about my dating service?
                            </label>
                            <select className="form-control" name="program">
                              <option value="Friend">Friend</option>
                              <option value="Wall Ad">Wall Ad</option>
                              <option value="Newspaper">Newspaper</option>
                              <option value="Radio">Radio</option>
                              <option value="The MBP Video">
                                The MBP Video
                              </option>
                              <option value="Facebook Ads">Facebook Ads</option>
                              <option value="TV">TV</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              className="form-control "
                              rows="3"
                              name="message"
                              id="message"
                              required
                            />
                            <label htmlFor="message">How can I help you?</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="webriq-recaptcha" />
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <button className="btn-global w-100" type="submit">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-section">
            <div className="container">
              <div className="text-white">
                <div className="row align-items-center">
                  <div className="col-md-6 text-center text-md-left">
                    <span>
                      {new Date().getFullYear()} Perfectly Matched.<sup>©</sup>
                      <div className="text-center text-md-left d-block d-lg-inline-block">
                        <span className="d-none d-lg-inline-block">
                          &nbsp;|&nbsp;
                        </span>
                        <Link to="/privacy-policy">Privacy Policy</Link> |{' '}
                        <Link to="/contact">Contact Me</Link> |{' '}
                        <a
                          href="http://www.facebook.com/perfectlymatched"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-btn"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </div>
                    </span>
                  </div>
                  <div className="col-md-6 text-center text-md-right">
                    © {new Date().getFullYear()} |
                    <span>
                      Designed and powered By&nbsp;
                      <a
                        href="https://www.webriq.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        WebriQ
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Layout
