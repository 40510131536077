import React from 'react'

const ScheduleSection = () => (
  <div className="text-center bg-pink-gradient schedule-section">
    <div className="container">
      <h3 className="text-white m-0 pb-4">
        Schedule your no-obligation interview now
      </h3>
      <a className="btn-global btn-white" href="tel:+17192601000">
        Click or call (719) 260-1000
      </a>
    </div>
  </div>
)

export default ScheduleSection
